import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// standaard menu
export const selectAllMenuDefapps = createSelector(scheme.Menu);

export const selectAllMenuDefappsByDefappsId = createSelector(
    scheme,
    (state, _defappsid) => selectAllMenuDefapps(state),
    (_state, defappsid) => defappsid,
    (_state, defapps, defappsid) => {
        return defapps?.find((app) => app.defappsid === defappsid);
    },
);

/*
Dit is de update component. Op deze pagina kan een gebruiker een profielnaam wijzigen. 
*/
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import { capitalize } from "lodash";

import CustomDialog from "../../../components/CustomDialog";
import { PROFILE_PATHS } from "../../../routes/paths/profiles";
import { updateProfile } from "../../../store/actions";
import { selectAllProfilesByProfilesId } from "../../../store/selectors";
import { getProfileValidationScheme } from "../../../validation/profiles";
import Content from "./Content";

function UpdateDialog({ updateProfile }) {
    const { profilesId } = useParams();
    const profile = useSelector((state) => selectAllProfilesByProfilesId(state, Number(profilesId)));
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            description: profile?.description ?? "",
        },
        enableReinitialize: true,
        validationSchema: getProfileValidationScheme(),
        onSubmit: () => {
            handleSubmit();
        },
    });

    //sluit de dialoog
    const handleClose = () => {
        navigate(PROFILE_PATHS.DEFAULT_PATH);
    };

    //invoeren van de wijziging in de database.
    const handleSubmit = async () => {
        await updateProfile({
            ...profile,
            description: capitalize(formik.values.description),
        });
        handleClose();
    };

    return (
        <CustomDialog
            title={t("profileComponent.dialog.dialogTitleU")}
            id="editProfileDialog"
            draggable={true}
            animated={true}
            maxWidth="sm"
            closeWithIcon={true}
            fullWidth={true}
            open={true}
            handleClose={handleClose}
            handleSubmit={formik.submitForm}
            defaultButtons={[
                {
                    label: t("profileComponent.dialog.dialogActionC"),
                    isPrimary: false,
                    isSubmit: false,
                },
                {
                    label: t("userComponent.dialog.dialogActionS"),
                    isPrimary: true,
                    isSubmit: true,
                },
            ]}
            content={<Content formik={formik} isEdit={true} />}
        />
    );
}
const mapDispatchToProps = {
    updateProfile,
};
export default connect(null, mapDispatchToProps)(UpdateDialog);

import { Model, attr } from "redux-orm";

import { RESET_AUTHVERSIONS, UPSERT_AUTHVERSIONS } from "../../constants/redux";

class AuthVersions extends Model {
    static reducer(action, AuthVersions) {
        switch (action.type) {
            case UPSERT_AUTHVERSIONS:
                action.payload?.forEach((version) => {
                    AuthVersions.upsert(version);
                });
                break;
            case RESET_AUTHVERSIONS:
                AuthVersions.delete();
                break;
            default:
                break;
        }
    }
}
AuthVersions.modelName = "AuthVersions";

AuthVersions.options = {
    idAttribute: "licversionsid", // default idd
};
// attributen
AuthVersions.fields = {
    $id: attr(),
    licversionsid: attr(),
    versioncode: attr(),
    versiondescr: attr(),
};

export default AuthVersions;

import axios from "axios";

import { SUCCESS } from "../../../constants/redux";
import { getURL } from "../../../utils/common";
import { handleErrors } from "../../../utils/helpers";

// change password email
export const sendResetPasswordEmail = (email, navigate) => async (dispatch) => {
    try {
        await axios.post(`${getURL()}requestresetpassword`, {
            email: email,
        });

        dispatch({
            type: SUCCESS,
            payload: `Email sent to - ${email}`,
        });
        navigate("/");
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// change password email
export const resetPassword = (key, password, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${getURL()}resetpassword`, {
            key: key,
            password: password,
        });

        dispatch({
            type: SUCCESS,
            payload: response.value,
        });
        // redirect naar login pagina
        navigate("/");
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

export const setPassword = (key, password, navigate) => async (dispatch) => {
    try {
        const response = await axios.post(`${getURL()}setpassword`, {
            key: key,
            password: password,
        });

        dispatch({
            type: SUCCESS,
            payload: response.value,
        });
        // redirect naar login pagina
        navigate("/");
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import Page404 from "../pages/auth/Page404";
import { useAuth } from "../services/auth/AuthProvider";
import { getToken } from "../utils/common";
import {
    portal as appsRoutes,
    dashboard as dashboardRoutes,
    notProtectedAuth as notProtectedAuthRoutes,
    protectedAuth as protectedAuthRoutes,
} from "./routes";

const renderRoutes = (routes) =>
    routes.map(({ children, path, element: Component, id }) => {
        return (
            <Route key={id} path={path} element={<Component />}>
                {children && renderRoutes(children)}
            </Route>
        );
    });

// ProtectedRoutes component
const ProtectedRoutes = ({ children, requiredToken }) => {
    const { auth } = useAuth();

    const hasAccess =
        (requiredToken === "default_access_token" && (auth.default_access_token ?? getToken())) ||
        (requiredToken === "access_token" && auth.access_token);

    if (!hasAccess) {
        return <Navigate to="/" replace />;
    }

    return children ? children : <Outlet />;
};

const NavigationRoutes = () => {
    return (
        <Router future={{ v7_startTransition: true }}>
            <Routes>
                <Route element={<AuthLayout />}>
                    {renderRoutes(notProtectedAuthRoutes)}
                    {/* Routes that require default_access_token */}
                    <Route element={<ProtectedRoutes requiredToken="default_access_token" />}>
                        {renderRoutes(protectedAuthRoutes)}
                    </Route>
                </Route>
                <Route element={<LandingLayout />}>
                    <Route element={<ProtectedRoutes requiredToken="default_access_token" />}>
                        {renderRoutes(appsRoutes)}
                    </Route>
                </Route>
                {/* Routes that require access_token for Dashboard */}
                <Route element={<DashboardLayout />}>
                    <Route element={<ProtectedRoutes requiredToken="access_token" />}>
                        {renderRoutes(dashboardRoutes)}
                    </Route>
                </Route>

                {/* Fallback route for 404 */}
                <Route
                    path="*"
                    element={
                        <AuthLayout>
                            <Page404 />
                        </AuthLayout>
                    }
                />
            </Routes>
        </Router>
    );
};

export default NavigationRoutes;

import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// standaard profielen
export const selectAllProfiles = createSelector(scheme, (state) => {
    return state.Profiles?.all()
        .toRefArray()
        ?.sort((a, b) => {
            if (a.description.toUpperCase() < b.description.toUpperCase()) {
                return -1;
            }
            if (a.description.toUpperCase() > b.description.toUpperCase()) {
                return 1;
            }
            return 0;
        });
});

export const selectAllProfilesByProfilesId = createSelector(
    scheme,
    (state, _profilesId) => selectAllProfiles(state),
    (_state, profilesId) => profilesId,
    (_state, profiles, profilesId) => {
        return profiles?.filter((p) => p.profilesid === profilesId);
    },
);

export const selectAllProfilesByDefappsId = createSelector(
    scheme,
    (state, _currentDefappsId) => selectAllProfiles(state),
    (_state, currentDefappsId) => currentDefappsId,
    (_state, profiles, currentDefappsId) => {
        return profiles?.filter((p) => p.defappsid === currentDefappsId);
    },
);

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

/**
 *
 * Component om oogje te laten zien bij het invoeren van een password. Kan als "end adornment" worden meegegeven
 * @param {boolean} isPasswordVisible oogje aan of uit
 * @param {funtion} setIsPasswordVisible functie om oogje aan of uit te zetten
 * @param {object} iconButtonProps
 *
 */
export default function PasswordVisibility({ isPasswordVisible, setIsPasswordVisible, iconButtonProps }) {
    return (
        <InputAdornment position="end">
            <IconButton
                {...iconButtonProps}
                color={iconButtonProps?.color ?? "primary"}
                size={iconButtonProps?.size ?? "small"}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                onMouseDown={(e) => e.preventDefault()}
            >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
}

import { ORM } from "redux-orm";

import AuthApps from "./authAppsModel";
import AuthLicenses from "./authLicensesModel";
import AuthVersions from "./authVersionsModel";
import Menu from "./headerMenuModel";
import Integrations from "./integrationsModel";
import AmountLicenseUsers from "./licenseusersModel";
import Profiles from "./profileModel";
import ProfileSettings from "./profileSettingsModel";
import Users from "./userModel";
import Versions from "./versionModel";

const orm = new ORM({
    stateSelector: (state) => state.scheme,
});
orm.register(
    Users,
    Profiles,
    ProfileSettings,
    Versions,
    Menu,
    AuthVersions,
    AuthLicenses,
    AuthApps,
    AmountLicenseUsers,
    Integrations,
);

export default orm;

import axios from "axios";

import { SUCCESS } from "../../../constants/redux";
import apiService from "../../../services/api/apiService";
import { getURL } from "../../../utils/common";
import { handleErrors } from "../../../utils/helpers";

// email opnieuw versturen
export const resendEmail = (id) => async (dispatch) => {
    try {
        await apiService.post(`requestemailverification`, { usersid: Number(id) });

        dispatch({
            type: SUCCESS,
            payload: `Email sent to user - ${id}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// verify email
export const verifyEmail = (key, navigate) => async (dispatch) => {
    try {
        await axios.post(`${getURL()}verifyemail`, { key: key });
    } catch (error) {
        navigate("/");
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

import { t } from "i18next";

import { SUCCESS } from "../../../constants/redux";
import apiService from "../../../services/api/apiService";
import { handleErrors } from "../../../utils/helpers";
import { fetchVersions } from "../versionActions";

// haal de sessie bij de geselecteerde app op
export const getSessions = (session) => async (dispatch) => {
    try {
        const response = await apiService.get(`myusersessions`, session);

        return response.data.value;
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// sluit de sessie bij de geselecteerde app op
export const endSession = (sessionId, option, navigate, defappsid) => async (dispatch) => {
    try {
        await apiService.delete(`kickusersessions`, {
            usersessionsid: sessionId,
        });
        // in applicatiebeheer update daarna de versie
        if (option === "versions") {
            dispatch(fetchVersions(defappsid));
            dispatch({
                type: SUCCESS,
                payload: t("sessionComponent.successMessage"),
            });
        }
        // niet in applicatiebeheer toon success bericht
        else {
            return true;
        }
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

import { Folder, Link, Unlock, Users } from "react-feather";

import ConfirmDialog from "../components/ConfirmDialog";
import async from "../components/standardComponents/Async";
import CreateApiDialog from "../pages/integration/Create";
import CreateProfileDialog from "../pages/profiles/dialog/Create";
import EditProfileDialog from "../pages/profiles/dialog/Update";
import CreateUserDialog from "../pages/users/dialog/Create";
import EditUserDialog from "../pages/users/dialog/Update";
import AddUserDialog from "../pages/versions/AddUserDialog";
import { INTEGRATION_PATHS } from "./paths/integrations";
import { PROFILE_PATHS } from "./paths/profiles";
import { USER_PATHS } from "./paths/users";
import { VERSION_PATHS } from "./paths/versions";

const Login = async(() => import("../pages/auth/Login"), "login");
const Integrations = async(() => import("../pages/integration"), "integrations");
const Profile = async(() => import("../pages/profiles"), "profile");
const Version = async(() => import("../pages/versions"), "version");
const User = async(() => import("../pages/users"), "user");
const AppsPortal = async(() => import("../pages/auth/AppsPortal"), "appsportal");
const VersionSelection = async(() => import("../pages/auth/VersionSelection"), "versionselection");
const LicenseSelection = async(() => import("../pages/auth/LicenseSelection"), "licenseselection");
const VerifyAccount = async(() => import("../pages/auth/VerifyAccount"), "verifyaccount");
const RequestResetPassword = async(() => import("../pages/auth/RequestResetPassword"), "requestresetpassword");
const ResetPassword = async(() => import("../pages/auth/ResetPassword"), "resetpassword");
const Validate = async(() => import("../pages/auth/2FactorAuth/TwoFactorAuth"), "validate");
const Renewsession = async(() => import("../pages/auth/RenewSession"), "renewsession");

//Sidenav componenten
const LoginRoutes = {
    id: "login",
    path: "/",
    name: "Login",
    containsHome: true,
    element: Login,
    children: null,
};
const LoginWithAppcodeRoutes = {
    id: "login",
    path: "/app/:appcode",
    name: "Login",
    containsHome: true,
    element: Login,
    children: null,
};
const loginwithappcodeandicenseAndVersionRoutes = {
    id: "login",
    path: "/app/:appcode/:licensesid/:licversionsid",
    name: "Login",
    containsHome: true,
    element: Login,
    children: null,
};

const ResetPasswordRoutes = {
    id: "requestreset",
    path: "/forgot_my_password",
    name: "RequestReset",
    element: RequestResetPassword,
    children: null,
};
const TwoFactorRoutes = {
    id: "twofactorauth",
    path: "/validate",
    name: "validate",
    element: Validate,
    children: null,
};
const ValidateRoutes = {
    id: "verify",
    path: "/verifyuser/:Id",
    name: "Verify",
    element: VerifyAccount,
    children: null,
};

const ResetRoutes = {
    id: "resetpassword",
    path: "/resetpassword/:Id",
    name: "ResetPassword",
    element: ResetPassword,
    children: null,
};
const RequestRoutes = {
    id: "requestpassword",
    path: "/requestpassword/:Id",
    name: "ResetPassword",
    element: ResetPassword,
    children: null,
};

const VersionSelectionRoutes = {
    id: "version",
    path: "/selection/portal/app/:App/licenses/versions",
    name: "Version Selection",
    element: VersionSelection,
    children: null,
};
const LicenseSelectionRoutes = {
    id: "license",
    path: "/selection/licenses",
    name: "License Selection",
    element: LicenseSelection,
    children: null,
};

const AppsPortalRoutes = {
    id: "portal",
    path: "/selection/portal/apps",
    name: "AppsPortal",
    element: AppsPortal,
    children: null,
};

const ProfileRoutes = {
    id: "profiles",
    path: PROFILE_PATHS.DEFAULT_PATH,
    icon: <Unlock />,
    element: Profile,
    children: [
        { id: "edit_profiles", path: PROFILE_PATHS.EDIT_PATH, icon: null, element: EditProfileDialog },
        { id: "new_profiles", path: PROFILE_PATHS.NEW_PATH, icon: null, element: CreateProfileDialog },
        { id: "delete_profiles", path: PROFILE_PATHS.DELETE_PATH, icon: null, element: ConfirmDialog },
    ],
};

const UserRoutes = {
    id: "users",
    path: USER_PATHS.DEFAULT_PATH,
    icon: <Users />,
    element: User,
    children: [
        { id: "edit_user", path: USER_PATHS.EDIT_PATH, icon: null, element: EditUserDialog },
        { id: "new_user", path: USER_PATHS.NEW_PATH, icon: null, element: CreateUserDialog },
        { id: "delete_user", path: USER_PATHS.DELETE_PATH, icon: null, element: ConfirmDialog },
    ],
};

const VersionRoutes = {
    id: "versions",
    path: VERSION_PATHS.DEFAULT_PATH,
    icon: <Folder />,
    element: Version,
    children: [
        { id: "attach_user", path: VERSION_PATHS.NEW_PATH, icon: null, element: AddUserDialog },
        { id: "detach_user", path: VERSION_PATHS.DELETE_PATH, icon: null, element: ConfirmDialog },
    ],
};

const RenewSessionRoutes = {
    id: "renew",
    path: "/renewsession",
    icon: null,
    element: Renewsession,
    children: null,
};

const IntegrationRoutes = {
    id: "integrations",
    path: INTEGRATION_PATHS.DEFAULT_PATH,
    icon: <Link />,
    element: Integrations,
    children: [
        { id: "new_api", path: INTEGRATION_PATHS.NEW_PATH, icon: null, element: CreateApiDialog },
        { id: "delete_api", path: INTEGRATION_PATHS.DELETE_PATH, icon: null, element: ConfirmDialog },
    ],
};

export const portal = [AppsPortalRoutes];
export const dashboard = [VersionRoutes, ProfileRoutes, UserRoutes, IntegrationRoutes];
export const notProtectedAuth = [
    LoginRoutes,
    loginwithappcodeandicenseAndVersionRoutes,
    LoginWithAppcodeRoutes,
    ValidateRoutes,
    RenewSessionRoutes,
    TwoFactorRoutes,
    ResetRoutes,
    ResetPasswordRoutes,
    RequestRoutes,
];
export const protectedAuth = [LicenseSelectionRoutes, VersionSelectionRoutes];

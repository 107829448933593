import { Model, attr } from "redux-orm";

import { CREATE_PROFILE, DELETE_PROFILE, RESET_PROFILE, UPDATE_PROFILE, UPSERT_PROFILE } from "../../constants/redux";

class Profiles extends Model {
    static reducer(action, profiles) {
        switch (action.type) {
            case CREATE_PROFILE:
                profiles.create(action.payload);
                break;
            case UPSERT_PROFILE:
                action?.payload?.forEach((profile) => {
                    profiles.upsert(profile);
                });
                break;
            case UPDATE_PROFILE:
                profiles.withId(action.payload.profilesid).update(action.payload);
                break;
            case DELETE_PROFILE:
                profiles.withId(action.payload).delete();
                break;
            case RESET_PROFILE:
                profiles.delete();
                break;
            default:
                break;
        }
    }
}
Profiles.modelName = "Profiles";

Profiles.options = {
    idAttribute: "profilesid", // default idd
};
// attributen
Profiles.fields = {
    $id: attr(),
    profilesid: attr(),
    licensesid: attr(),
    defappsid: attr(),
    code: attr(),
    description: attr(),
    stddefauthlevelscode: attr(),
};

export default Profiles;

import { Model, attr } from "redux-orm";

import { UPSERT_MENU } from "../../constants/redux";

class Menu extends Model {
    static reducer(action, Menu) {
        switch (action.type) {
            case UPSERT_MENU:
                action?.payload?.forEach((app) => {
                    Menu.upsert(app);
                });

                break;
            default:
                break;
        }
    }
}
Menu.modelName = "Menu";

Menu.options = {
    idAttribute: "defappsid", // default idd
};
// attributen
Menu.fields = {
    $id: attr(),
    defappsid: attr(),
    code: attr(),
    description: attr(),
};

export default Menu;

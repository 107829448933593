import { groupBy } from "lodash";
import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// alle profielensettings groepeerd bij profileID
export const selectProfileSettingsByProfilesId = createSelector(scheme, (orm) => {
    const authGroups = orm.ProfileSettings.all()
        .toRefArray()
        .flatMap((item) => item.defAuthGroups);
    return groupBy(authGroups, (item) => item.ProfileAuths?.[0]?.profilesid);
    //.map(({$id, code, description,profileAuths})=>[$id, code, description,profileAuths])
});

// profiels defauthlevels --> waarde van de RDO, NOA, MDF opties
export const selectAllProfileSettings = createSelector(scheme, (orm) => {
    return orm.ProfileSettings.all()
        .toRefArray()
        .map((item) => item.defAuthLevels)?.[0];
});

import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { Box, CircularProgress, StyledEngineProvider, ThemeProvider } from "@mui/material";

import { jwtDecode } from "jwt-decode";

import Snackbars from "./components/Snackbars";
import UseInterceptors from "./hooks/useInterceptors";
import useRefreshToken from "./hooks/useRefreshToken";
import AuthLayout from "./layouts/Auth";
import RenewSession from "./pages/auth/RenewSession";
import Routes from "./routes/index";
import "./styles/App.css";
import maTheme from "./theme";
import { getApbRefreshToken } from "./utils/common";

function App({ theme }) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const refresh = useRefreshToken();

    useEffect(() => {
        const validateAndRefreshToken = async () => {
            // refresh token aanwezig valideer de jwt ander redirect naar inlogscherm
            const token = getApbRefreshToken();

            if (token) {
                try {
                    // controleer of url bevat een gelidige jwt die bestaat uit 3 gedeelte bijv. 'eyJ0eXAiOiJKV1Qi........'
                    jwtDecode(token);
                    try {
                        await refresh();
                        setIsLoading(false);
                    } catch {
                        setError(true);
                    }
                } catch (_err) {
                    window.location.href = `/`;
                }
            } else {
                setIsLoading(false);
            }
        };
        validateAndRefreshToken();
    }, []);

    return (
        <Fragment>
            <Helmet titleTemplate={"%s |  EVO-IT"} defaultTitle="EVO-IT" />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={maTheme[theme.currentTheme]}>
                    <UseInterceptors>
                        <Snackbars />
                        {isLoading ? (
                            <AuthLayout>
                                {!error ? (
                                    <Box>
                                        <CircularProgress /> <p>Loading...</p>
                                    </Box>
                                ) : (
                                    <RenewSession />
                                )}
                            </AuthLayout>
                        ) : (
                            <Routes />
                        )}
                    </UseInterceptors>
                </ThemeProvider>
            </StyledEngineProvider>
        </Fragment>
    );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);

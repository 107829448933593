import { initReactI18next } from "react-i18next";

import * as i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

import translationDEU from "./locales/de/translation-de.json";
import translationEN from "./locales/en/translation-en.json";
import translationNLD from "./locales/nl/translation-nl.json";
import { getLanguages } from "./utils/common";

const resources = {
    nl: {
        translation: translationNLD,
    },
    deu: {
        translation: translationDEU,
    },
    en: {
        translation: translationEN,
    },
};

i18n.use(backend) // load translation using http -> see /public/locales
    .use(languageDetector) // detect user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        //lng: "en",
        fallbackLng: "en",
        supportedLngs: getLanguages(),
        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

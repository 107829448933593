import { useDispatch } from "react-redux";

import axios from "axios";

import { useAuth } from "../services/auth/AuthProvider";
import { resetStore } from "../store/actions";
import { getURL } from "../utils/common";

const useLogout = () => {
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const clearStorage = () => {
        dispatch(resetStore);
        // clear localforage and sessionStorage
        sessionStorage.clear();
        // setAuth();
        //redirect gebruiker naar login
        window.location.href = `/`;
    };

    const logout = async () => {
        try {
            await axios.post(
                `${getURL()}logout`,
                {},
                {
                    headers: { Authorization: `Bearer ${auth.access_token}` },
                },
            );

            clearStorage();
        } catch (_err) {
            clearStorage();
        }
    };

    return logout;
};
export default useLogout;

import { Fragment, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink as RouterNavLink } from "react-router-dom";

import { ChevronLeft, ChevronRight, ExitToApp } from "@mui/icons-material";
import {
    Chip,
    Grid,
    Link as LinkBrand,
    ListItem,
    ListItemText,
    Drawer as MuiDrawer,
    List as MuiList,
    Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";
import { darken } from "polished";

import { colorYellow } from "../../constants/colors";
import useLogout from "../../hooks/useLogout";
import { VERSION_PATHS } from "../../routes/paths/versions";
import { dashboard as routes } from "../../routes/routes";
import "../../styles/perfect-scrollbar.css";
import LanguagesMenu from "./Languages";

//logo navigatie
const LogoApb = styled.img`
    border-radius: 50%;
`;

const NavLink = forwardRef(function Nav(props, ref) {
    return <RouterNavLink ref={ref} {...props} />;
});

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;
const Scrollbar = styled(PerfectScrollbar)`
    background-color: ${(props) => props.theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
    background-color: ${(props) => props.theme.sidebar.background};
`;

const Brand = styled(ListItem)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    padding-left: 3px;
    padding-right: 3px;
    align-items: center;
    justify-content: center;

    ${(props) => props.theme.breakpoints.up("sm")} {
        min-height: 155px;
    }
`;

const BrandIcon = styled(LogoApb)`
    color: ${(props) => props.theme.sidebar.header.brand.color};
`;

// .svg--> opacity: 0.5 sidebar icoon verwijderd;
const Category = styled(ListItem)`
    padding-top: ${(props) => props.theme.spacing(2)};
    padding-bottom: ${(props) => props.theme.spacing(2)};
    padding-left: 3px;
    padding-right: 3px;

    svg {
        color: ${(props) => props.theme.sidebar.color};
        font-size: 20px;
        width: 25px;
        height: auto;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }

    &.${(props) => props.activeclassname} {
        background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};
        border-style: solid;
        border-color: ${"transparent"};
        border-left-color: ${colorYellow};

        span {
            color: ${(props) => props.theme.sidebar.color};
        }
        svg {
            color: ${(props) => props.theme.sidebar.color};
        }
    }
`;

const CategoryText = styled(ListItemText)`
    span {
        color: ${(props) => props.theme.sidebar.color};
        font-size: ${(props) => props.theme.typography.body1.fontSize};
        font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    }
`;

const LinkBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
    background: ${(props) => props.theme.sidebar.badge.background};

    span.MuiChip-label,
    span.MuiChip-label:hover {
        cursor: pointer;
        color: ${(props) => props.theme.sidebar.badge.color};
    }
`;

const CategoryBadge = styled(LinkBadge)`
    top: 12px;
`;

const SidebarFooter = styled.div`
    background-color: ${(props) => props.theme.sidebar.footer.background} !important;
    padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(0)}px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

function SidebarCategory({ name, tooltipTitle, icon, badge, ...rest }) {
    return (
        <Tooltip disableInteractive title={tooltipTitle} disableHoverListener={Boolean(name)}>
            <Category {...rest}>
                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                    <Grid item xs>
                        {icon}
                    </Grid>
                    <Grid item xs>
                        <CategoryText>{name}</CategoryText>
                    </Grid>
                </Grid>

                {badge ? <CategoryBadge label={badge} /> : ""}
            </Category>
        </Tooltip>
    );
}

function Sidebar({ handleExpand, expanded, ...rest }) {
    const { t } = useTranslation();
    const logout = useLogout();

    const handleLogout = () => {
        logout();
    };

    return (
        <Drawer variant="permanent" {...rest}>
            <Brand>
                <LinkBrand href={VERSION_PATHS.DEFAULT_PATH}>
                    <BrandIcon
                        style={{ width: expanded ? "90px" : "70px" }}
                        src="/static/applicatiebeheer.svg"
                        alt="Logo"
                    />
                </LinkBrand>
            </Brand>

            <Scrollbar>
                <List disablePadding>
                    {routes.map((category) => {
                        return (
                            <Fragment key={category.id}>
                                <SidebarCategory
                                    name={expanded ? t(`sidebarComponent.${category.id}`) : ""}
                                    tooltipTitle={t(`sidebarComponent.${category.id}`)}
                                    to={category.path}
                                    activeclassname="active"
                                    component={NavLink}
                                    icon={category.icon}
                                    badge={category.badge}
                                />
                            </Fragment>
                        );
                    })}
                </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container alignItems="center" spacing={0} direction="column">
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <SidebarCategory
                            name={expanded ? t("sidebarComponent.language") : ""}
                            icon={<LanguagesMenu />}
                            button={true}
                            tooltipTitle={t("sidebarComponent.language")}
                            style={{ width: "100%" }}
                        />
                    </Grid>

                    {/* 
          
          // tijdelijk uitschakelen, want we doen niks met help hier
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid item xs>
                <IconButton size="small">
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs>
                      <HelpOutline style={{ height: "30px", width: "30px" }} />
                    </Grid>

                    <Grid item xs>
                      <Typography style={{ color: "white" }}>
                        {t("sidebarComponent.help")}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Grid>
            </Grid>
          </Grid> */}
                    <Grid item xs={12} sx={{ width: "100%" }}>
                        <SidebarCategory
                            name={expanded ? t("sidebarComponent.exit") : ""}
                            icon={<ExitToApp style={{ height: "30px", width: "30px" }} />}
                            button={true}
                            onClick={handleLogout}
                            tooltipTitle={t("sidebarComponent.exit")}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ width: "100%" }}>
                        <SidebarCategory
                            name={""}
                            icon={expanded ? <ChevronLeft /> : <ChevronRight color="primary" />}
                            button={true}
                            onClick={handleExpand}
                            tooltipTitle={
                                expanded
                                    ? t("sidebarComponent.hide_explanation")
                                    : t("sidebarComponent.show_explanation")
                            }
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
}

export default Sidebar;

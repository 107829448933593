import dayjs from "dayjs";
import { createSelector } from "redux-orm";

import { APPLICATIONMANAGER } from "../../constants/general";
import scheme from "../../store/models/orm";
import { formatDateToYYYYMMDD } from "../../utils/helpers";

// status wordt obv einddatum en emailvalidated bepaald
function formatStatus(date, emailvalidated) {
    const today = formatDateToYYYYMMDD(dayjs());

    if ((date >= today && emailvalidated === true) || (date === "" && emailvalidated === true)) {
        return "active";
    } else if (date < today && emailvalidated === true) {
        return "inactive";
    } else if ((date >= today && emailvalidated === false) || (date === "" && emailvalidated === false)) {
        return "pending";
    } else {
        return "inactive";
    }
}

// alle gebruikers linked defapps
export const selectAllUsersDefapps = createSelector(scheme.Users, (users) => {
    return users.map((d) => ({
        id: d.usersid,
        defapps: d.defapps.filter((p) => p.code !== APPLICATIONMANAGER),
        amount: d.defapps.filter((p) => p.code !== APPLICATIONMANAGER).length,
    }));
});

// alle gebruikers om userstabel te vullen
export const selectAllUsers = createSelector(scheme, (orm) => {
    return orm.Users.all()
        .toRefArray()
        .sort((a, b) => {
            if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
                return -1;
            }
            if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
                return 1;
            }
            return 0;
        })
        .map((usr) => ({
            ...usr,
            fullName: [usr.firstname, usr.infixname, usr.lastname].filter((part) => part).join(" "),
            isAdmin: usr.defapps.some((i) => i.code === APPLICATIONMANAGER),
            lastlogin: formatDateToYYYYMMDD(usr.lastlogin),
            expdate: usr.expdate,
            status: formatStatus(formatDateToYYYYMMDD(usr.expdate), usr.emailverified),
        }));
});

export const selectAllUsersByUsersId = createSelector(
    scheme,
    (state, _usersid) => selectAllUsers(state),
    (_state, usersid) => usersid,
    (_state, users, usersid) => {
        return users?.find((p) => p.usersid === usersid);
    },
);

// alle gebruikers linked defapps
export const selectAllAvailableUsers = createSelector(scheme, (orm) => {
    return orm.Users.all()
        .toModelArray()
        .sort((a, b) => {
            if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
                return -1;
            }
            if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
                return 1;
            }
            return 0;
        })
        .map(({ usersid, loginemail, fullName }) => [usersid, loginemail, fullName]);
});

import { t } from "i18next";

export function getLocale(string) {
    return {
        mixed: {
            // default error bij required gebruikt de path/naam (indicating where the error was thrown) van de component en voeg verplicht toe
            required: ({ path }) => `*${t(`${string}.${path}`)} ${t("loginComponent.input.required")}`,
        },
    };
}

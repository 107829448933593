import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// standard integraties
export const selectAllIntegrations = createSelector(scheme.Integrations);

export const selectAllIntegrationsByApikeysId = createSelector(
    scheme,
    (state, _apikeysid) => selectAllIntegrations(state),
    (_state, apikeysid) => apikeysid,
    (_state, integrations, apikeysid) => {
        return integrations?.find((p) => p.apikeysid === apikeysid);
    },
);

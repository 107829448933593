/*
Dit is de Snackbarmelding component. Op deze pagina wordt bepaald of een melding een succes of error melding is. 
als de melding een error is wordt de snackbar rood en als de melding succes is wordt de snackbar groen.
*/
import { connect, useSelector } from "react-redux";

import { Alert as MuiAlert, Snackbar } from "@mui/material";

import { finishMessage } from "../store/actions";
import { currentMessage } from "../store/selectors";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snackbars(props) {
    const message = useSelector(currentMessage);

    const handleAlertClose = () => {
        props.finishMessage();
    };
    return message ? (
        /*
    snackbar om succes- of foutmelding te geven. 
    als type is error duur is 6 seconde & kleur is rood.
    als type is succes duur is 2 seconde & kleur is groen.
    */
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={message.type === "error" ? 6000 : 2000}
            onClose={handleAlertClose}
        >
            <div>
                {message.type === "error" ? (
                    <Alert variant="filled" severity="error" style={{ backgroundColor: "#A70C00" }}>
                        {message.message}
                    </Alert>
                ) : (
                    <Alert severity="success">{message.message}</Alert>
                )}
            </div>
        </Snackbar>
    ) : null;
}

const mapDispatchToProps = {
    finishMessage,
};

export default connect(null, mapDispatchToProps)(Snackbars);

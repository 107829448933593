import axios from "axios";

import { local_backend_ip_address } from "../../utils/common";

const BASE_URL = `${process.env.REACT_APP_API_URL || `http://${local_backend_ip_address}:9999/protserver/`}`;
// maak een instantie van axios
export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Accept: "application/json",
    },
    // `withCredentials` indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    //  withCredentials: true,
});

// get
const getRequest = (endpoint, params) => {
    return axiosInstance.get(endpoint, {
        params: {
            ...params,
        },
        //  withCredentials: true,
    });
};

// post
const postRequest = (endpoint, params) => {
    return axiosInstance.post(endpoint, params);
};

// delete
const deleteRequest = (endpoint, params) => {
    return axiosInstance.delete(endpoint, {
        params: { ...params },
    });
};

const apiService = {
    get: getRequest,
    post: postRequest,
    delete: deleteRequest,
};
export default apiService;

import { t } from "i18next";

import { CREATE_PROFILE, DELETE_PROFILE, ERROR, SUCCESS, UPDATE_PROFILE, UPSERT_PROFILE } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal alle profielen op
export const fetchProfiles = (defappsid) => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/profiles`, {
            Top: 100,
            defappsid: defappsid,
        });

        dispatch({
            type: UPSERT_PROFILE,
            payload: response.data.value,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// profiel wijzigen
export const updateProfile = (profile) => async (dispatch) => {
    try {
        if (profile.description !== "") {
            // update profiel in de database
            const updateResponse = await apiService.post(`appmanagement/profiles`, profile);

            dispatch({
                type: UPDATE_PROFILE,
                payload: profile,
            });

            dispatch({
                type: SUCCESS,
                payload: t("profileComponent.messages.update") + ` -  ${updateResponse.data.value}`,
            });
        } else {
            dispatch({
                type: ERROR,
                payload: t("profileComponent.messages.empty"),
            });
        }
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// maak nieuw profiel aan
export const createProfile = (profile) => async (dispatch) => {
    try {
        const createResponse = await apiService.post(`appmanagement/profiles`, profile);

        dispatch({
            type: CREATE_PROFILE,
            payload: {
                ...profile,
                profilesid: createResponse.data.value,
                licensesid: getLicense(),
            },
        });

        dispatch({
            type: SUCCESS,
            payload: t("profileComponent.messages.create") + ` -  ${createResponse.data.value}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// verwijderd profiel
export const deleteProfile = (id) => async (dispatch) => {
    try {
        await apiService.delete(`appmanagement/profiles`, { profilesid: id });

        // verwijderd alleen de geselecteerde profiel in de orm
        dispatch({
            type: DELETE_PROFILE,
            payload: id,
        });
        dispatch({
            type: SUCCESS,
            payload: t("profileComponent.messages.delete") + ` - ${id}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// Thema
export const SET_THEME = "SET_THEME";
// Gebruikers
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPSERT_USER = "UPSERT_USER";
export const DELETE_USER = "DELETE_USER";
// Profielen
export const CREATE_PROFILE = "CREATE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPSERT_PROFILE = "UPSERT_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const RESET_PROFILE = "RESET_PROFILE";
// ProfielSettings
export const CREATE_PROFILESETTINGS = "CREATE_PROFILESETTINGS";
export const UPDATE_PROFILESETTINGS = "UPDATE_PROFILESETTINGS";
export const UPSERT_PROFILESETTINGS = "UPSERT_PROFILESETTINGS";
export const RESET_PROFILESETTINGS = "RESET_PROFILESETTINGS";
// Versies
export const CREATE_VERSION = "CREATE_VERSION";
export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPSERT_VERSION = "UPSERT_VERSION";
export const RESET_VERSION = "RESET_VERSIONS";
// Aantal glicentie gebruikers
export const CREATE_AMOUNTLICENSEUSERS = "CREATE_AMOUNTLICENSEUSERS";
export const UPDATE_AMOUNTLICENSEUSERS = "UPDATE_AMOUNTLICENSEUSERS";
export const DELETE_AMOUNTLICENSEUSERS = "DELETE_AMOUNTLICENSEUSERS";
export const UPSERT_AMOUNTLICENSEUSERS = "UPSERT_AMOUNTLICENSEUSERS";
// Snackbar
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const FINISHED_MESSAGE = "FINISHED_MESSAGE";
// Header defapps
export const UPSERT_MENU = "UPSERT_MENUAPPS";
// Auth Login
export const LOGOUT = "LOGOUT";

// Session
export const UPSERT_SESSION = "UPSERT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const RESET_SESSION = "RESET_SESSION";

// License Versions
export const UPSERT_AUTHVERSIONS = "UPSERT_AUTHVERSIONS";
export const RESET_AUTHVERSIONS = "RESET_AUTHVERSIONS";
// Licenses
export const UPSERT_AUTHLICENSES = "UPSERT_AUTHLICENSES";
// Auth Apps
export const UPSERT_AUTHAPPS = "UPSERT_AUTHAPPS";
export const RESET_AUTHAPPS = "RESET_AUTHAPPS";

// integraties
export const CREATE_INTEGRATIONS = "CREATE_INTEGRATIONS";
export const UPDATE_INTEGRATIONS = "UPDATE_INTEGRATIONS";
export const DELETE_INTEGRATIONS = "DELETE_INTEGRATIONS";
export const UPSERT_INTEGRATIONS = "UPSERT_INTEGRATIONS";

import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

export const selectAllAuthApps = createSelector(scheme, (orm) => {
    return orm.AuthApps.all()
        .toModelArray()
        .map(({ $id, defappsid, code, description }) => ({
            $id,
            defappsid,
            code,
            description,
        }));
});

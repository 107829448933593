import { Model, attr } from "redux-orm";

import {
    CREATE_AMOUNTLICENSEUSERS,
    DELETE_AMOUNTLICENSEUSERS,
    UPDATE_AMOUNTLICENSEUSERS,
    UPSERT_AMOUNTLICENSEUSERS,
} from "../../constants/redux";

class AmountLicenseUsers extends Model {
    static reducer(action, amountLicenseUsers) {
        switch (action.type) {
            case CREATE_AMOUNTLICENSEUSERS:
                amountLicenseUsers.create(action.payload);
                break;
            case UPSERT_AMOUNTLICENSEUSERS:
                amountLicenseUsers.upsert(action.payload);
                break;
            case UPDATE_AMOUNTLICENSEUSERS:
                amountLicenseUsers.withId(action.payload.$id).update(action.payload);
                break;
            case DELETE_AMOUNTLICENSEUSERS:
                amountLicenseUsers.withId(action.payload).delete();
                break;
            default:
                break;
        }
    }
}
AmountLicenseUsers.modelName = "AmountLicenseUsers";

AmountLicenseUsers.options = {
    idAttribute: "$id", // default idd
};
// attributen
AmountLicenseUsers.fields = {
    $id: attr(),
    readcount: attr(),
    modifycount: attr(),
    readlimit: attr(),
    modifylimit: attr(),
};

export default AmountLicenseUsers;

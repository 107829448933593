import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { VpnKey } from "@mui/icons-material";
import { Box, DialogContentText, Grid, Typography } from "@mui/material";

import CustomDialog from "../../components/CustomDialog";
import { colorRed, primaryColor } from "../../constants/colors";
import { INTEGRATION_PATHS } from "../../routes/paths/integrations";

export default function ShowKeyDialog({ apikey, open, close }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [copy, setCopy] = useState(false);
    const handleClose = () => {
        close();
        navigate(INTEGRATION_PATHS.DEFAULT_PATH);
    };
    useEffect(() => {
        setTimeout(function () {
            setCopy(false);
        }, 10000);
    }, [copy]);

    return (
        <CustomDialog
            id="nieuwApiDialog"
            open={open}
            handleClose={handleClose}
            handleSubmit={() => {
                setCopy(true);
                navigator.clipboard.writeText(apikey);
            }}
            title={t("integrationComponent.dialog.show")}
            content={
                <>
                    <DialogContentText>{`${t("integrationComponent.dialog.subtitle")}`}</DialogContentText>
                    <Grid container wrap="nowrap" spacing={2} alignItems="center">
                        <Grid item>
                            <Box component={VpnKey} mr={2} color={primaryColor} />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography noWrap>{apikey}</Typography>
                        </Grid>
                        {copy && (
                            <Grid item>
                                <span style={{ color: colorRed }}>{t("integrationComponent.dialog.copied")}</span>
                            </Grid>
                        )}
                    </Grid>
                </>
            }
            defaultButtons={[
                {
                    label: t("integrationComponent.dialog.buttons.close"),
                    isPrimary: false,
                    isSubmit: false,
                },
                {
                    label: t("integrationComponent.dialog.buttons.copy"),
                    isPrimary: true,
                    isSubmit: true,
                },
            ]}
        />
    );
}

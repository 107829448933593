import { useTranslation } from "react-i18next";

import { Box, FormControlLabel, Grid, TextField } from "@mui/material";

import { GreenRadio, OrangeRadio, RedRadio } from "../../../components/styledComponents/ColoredRadio";
import { MDF, NOA, RDO } from "../../../constants/profiles";

export default function Content({ formik, isEdit }) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    margin="dense"
                    id="description"
                    name="description"
                    label={t("profileComponent.dialog.input.description")}
                    onChange={formik.handleChange}
                    value={formik.values.description ?? ""}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched["description"] && Boolean(formik.errors["description"])}
                    size="small"
                />
            </Grid>
            {!isEdit && (
                <Grid container item xs={12} justifyContent="space-around">
                    <Box>
                        <FormControlLabel
                            checked={formik.values.selectedValue === NOA}
                            name="selectedValue"
                            value={NOA}
                            control={<RedRadio />}
                            label={t("profileComponent.dialog.input.noa")}
                            labelPlacement="end"
                            onChange={formik.handleChange}
                        />
                        <FormControlLabel
                            checked={formik.values.selectedValue === RDO}
                            value={RDO}
                            name="selectedValue"
                            control={<OrangeRadio />}
                            label={t("profileComponent.dialog.input.rdo")}
                            labelPlacement="end"
                            onChange={formik.handleChange}
                        />
                        <FormControlLabel
                            checked={formik.values.selectedValue === MDF}
                            name="selectedValue"
                            value={MDF}
                            control={<GreenRadio />}
                            label={t("profileComponent.dialog.input.mdf")}
                            labelPlacement="end"
                            onChange={formik.handleChange}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

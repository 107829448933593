import { ERROR, FINISHED_MESSAGE, SUCCESS } from "../../constants/redux";

export default function reducer(state = [], action) {
    switch (action.type) {
        case SUCCESS:
            return [...state, { message: action.payload, type: "success" }];
        case ERROR:
            return [...state, { message: action.payload, type: "error" }];
        case FINISHED_MESSAGE:
            return [];
        default:
            return state;
    }
}

import axios from "axios";

import { LICENSEMANAGER, LMSBACKOFFICE } from "../../../constants/general";
import { ERROR, LOGOUT } from "../../../constants/redux";
import { getToken, getURL, local_frontend_ip_address } from "../../../utils/common";

// // reset redux store
export const resetStore = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
};

// geen keuze gemaakt in de licenties of licentie versies
export const choiceIsEmpty = (message) => (dispatch) => {
    dispatch({
        type: ERROR,
        payload: message,
    });
};

// haal de apps url's
export const getClientUrl = async (selectedapp, tokens) => {
    const app = selectedapp.toUpperCase();
    const { access_token, refresh_token } = tokens;

    try {
        const response = await axios.get(`${getURL()}baseurl`, {
            params: { app: app },
            headers: { Authorization: `Bearer ${getToken()}` },
        });
        // voor local testen
        if (!process.env.REACT_APP_API_URL) {
            if (response.data.frontend_url !== "") {
                switch (app) {
                    case LICENSEMANAGER:
                        return `${response.data.frontend_url}login/${access_token}/${refresh_token}/${sessionStorage.getItem(
                            "app_user_license",
                        )}`;
                    case LMSBACKOFFICE:
                        return `${
                            response.data.frontend_url
                        }login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
                            "app_user_license",
                        )}/${sessionStorage.getItem("app_user_version")}`;
                    default:
                        return null;
                }
            } else {
                switch (app) {
                    // licentiebeheer url
                    case LICENSEMANAGER:
                        return `http://${local_frontend_ip_address}:3002/login/${access_token}/${refresh_token}/${sessionStorage.getItem(
                            "app_user_license",
                        )}`;
                    // lmsbackoffice url
                    case LMSBACKOFFICE:
                        return `http://${local_frontend_ip_address}:3001/login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
                            "app_user_license",
                        )}/${sessionStorage.getItem("app_user_version")}`;
                    default:
                        return null;
                }
            }
        }
        // op aws
        else {
            switch (app) {
                case LICENSEMANAGER:
                    return `${response.data.frontend_url}login/${access_token}/${refresh_token}/${sessionStorage.getItem(
                        "app_user_license",
                    )}`;
                case LMSBACKOFFICE:
                    return `${
                        response.data.frontend_url
                    }login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
                        "app_user_license",
                    )}/${sessionStorage.getItem("app_user_version")}`;
                default:
                    return null;
            }
        }
    } catch (_err) {
        // toon foutmelding
    }
};

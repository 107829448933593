import { combineReducers } from "redux";
import { createReducer } from "redux-orm";

import { LOGOUT } from "../../constants/redux";
import scheme from "../models/orm";
import snackbarReducer from "./snackbarReducers";
import themeReducer from "./themeReducers";

const schemeReducer = createReducer(scheme);
const clearSchemeReducer = (state, action) => {
    if (action.type === LOGOUT) {
        return scheme.getEmptyState();
    } else {
        return schemeReducer(state, action);
    }
};

export default combineReducers({
    themeReducer,
    snackbarReducer,
    scheme: clearSchemeReducer,
});

import { t } from "i18next";

import { DELETE_INTEGRATIONS, SUCCESS, UPSERT_INTEGRATIONS } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal alle apis op
export const fetchIntegrations = () => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/apikeys`);

        dispatch({
            type: UPSERT_INTEGRATIONS,
            payload: response.data.value?.apikeys || [],
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};
// maak nieuw apikey aan
export const createIntegration = (api) => async (dispatch) => {
    try {
        const params = {
            ...api,
        };

        const createResponse = await apiService.post(`appmanagement/apikey`, params);
        dispatch(fetchIntegrations());
        return createResponse.data.value;
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

export const deleteIntegration = (id, type) => async (dispatch) => {
    try {
        await apiService.delete(`appmanagement/apikey`, { apikeysid: id });

        // verwijderd alleen de geselecteerde profiel in de orm
        dispatch({
            type: DELETE_INTEGRATIONS,
            payload: id,
        });

        if (type === "renew") {
            return 0;
        } else {
            dispatch({
                type: SUCCESS,
                payload: t("profileComponent.messages.delete") + ` - ${id}`,
            });
        }
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// return the token from the session storage
export const getToken = () => {
    return sessionStorage.getItem("default_oauth") || null;
};

// return the applciation management refresh token from the session storage
export const getApbRefreshToken = () => {
    try {
        const oauth = JSON?.parse(sessionStorage.getItem("app_oauth"));
        return oauth?.refresh_token || null;
    } catch (_error) {
        return null;
    }
};

// return user language
export const getUserLang = () => {
    return sessionStorage.getItem("app_user_lang") || null;
};

// return user login gegecens
export const getUserTemp1 = () => {
    return sessionStorage.getItem("user_temp_log") || null;
};

export const getUserTemp2 = () => {
    return sessionStorage.getItem("user_temp_log2") || null;
};

// return user 2FA type
export const getTypeMFA = () => {
    return sessionStorage.getItem("typeMFA") || null;
};

// return the user selected license from the session storage
export const getLicense = () => {
    try {
        const licentieStr = sessionStorage.getItem("app_user_license");
        if (licentieStr) return JSON.parse(licentieStr);
        else return null;
    } catch (_err) {
        return null;
    }
};

export const removeUserTemp = () => {
    sessionStorage.removeItem("user_temp_log");
    sessionStorage.removeItem("user_temp_log2");
    sessionStorage.removeItem("typeMFA");
};

// set the user login gegevens tijdelijk in the session storage
export const setUserTemp1 = (data) => {
    sessionStorage.setItem("user_temp_log", data);
};

export const setUserTemp2 = (data) => {
    sessionStorage.setItem("user_temp_log2", data);
};

// set tje user 2FA type
export const setTypeMFA = (type) => {
    sessionStorage.setItem("typeMFA", type);
};

// set user selected language
export const setUserLang = (lang) => {
    switch (lang) {
        case "NLD":
        case "nl":
            sessionStorage.setItem("app_user_lang", "nl");
            break;
        case "ENG":
        case "en":
            sessionStorage.setItem("app_user_lang", "en");
            break;
        case "DEU":
        case "de":
            sessionStorage.setItem("app_user_lang", "de");
            break;
        default:
            sessionStorage.setItem("app_user_lang", "en");
            break;
    }
};

//API URL
export const getURL = () => {
    return process.env.REACT_APP_API_URL || `http://${local_backend_ip_address}:9999/protserver/`;
};
// backend ip adres in de env bestand anders 127.0.0.1/localhost
export const local_backend_ip_address = process.env.REACT_APP_LOCALIPADDRESS || "127.0.0.1";

// frontends ip adres
export const local_frontend_ip_address = process.env.REACT_APP_LOCALIPADDRESS_FRONTEND || "127.0.0.1";

export const getVersion = () => {
    return `${process.env.REACT_APP_REVISION || "development"} | ${window.location.hostname}`;
};

// supported languages
export const getLanguages = () => {
    return ["nl", "en"];
};

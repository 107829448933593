/* 
Dit is de confirm component. Op deze pagina wordt de keuze gemaakt om:
een gebruiker van een versie los te koppelen, de actieve sessie van een gebruiker af te sluiten,
een gebruiker te verwijderen en een profiel te verwijderen. In al deze optie worden verschillende informatie getoond in de dialoog.
*/
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Assignment, Check, Close, Computer, Folder, Person, Schedule, Today, VpnKey } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
} from "@mui/material";

import { secondaryColor } from "../constants/colors";
import { INTEGRATION_PATHS } from "../routes/paths/integrations";
import { PROFILE_PATHS } from "../routes/paths/profiles";
import { USER_PATHS } from "../routes/paths/users";
import { VERSION_PATHS } from "../routes/paths/versions";
import { useAuth } from "../services/auth/AuthProvider";
import { deleteIntegration, deleteProfile, deleteUser, detachUserFromVersion, endSession } from "../store/actions";
import {
    selectAllIntegrationsByApikeysId,
    selectAllProfilesByProfilesId,
    selectAllUsersByUsersId,
    selectAllVersionsByVersionId,
} from "../store/selectors";

function ConfirmDialog({ deleteUser, deleteProfile, endSession, detachUserFromVersion, deleteIntegration }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { userId, action, profilesId, versionId, Id, apiKey } = useParams();
    const user = useSelector((state) => selectAllUsersByUsersId(state, Number(userId)));
    const profile = useSelector((state) => selectAllProfilesByProfilesId(state, Number(profilesId))) ?? [];
    const version = useSelector((state) => selectAllVersionsByVersionId(state, Number(versionId))) ?? [];
    const api = useSelector((state) => selectAllIntegrationsByApikeysId(state, apiKey)) ?? [];
    const sessionInfo =
        action === "end_session"
            ? version?.userlicapplinks
                  ?.filter((x) => x.usersid === Number(userId))
                  ?.map((y) => [y.ip, y.logindatetime.substring(0, 10), y.logindatetime.substring(11, 19)])?.[0]
            : [];

    const handleClose = () => {
        switch (action) {
            //gebruiker verwijderen
            case "delete_user":
                navigate(USER_PATHS.DEFAULT_PATH);
                break;
            // profiel verwijderen
            case "delete_profile":
                navigate(PROFILE_PATHS.DEFAULT_PATH);
                break;

            case "delete_apikey":
                navigate(INTEGRATION_PATHS.DEFAULT_PATH);
                break;
            //default versie
            default:
                navigate(VERSION_PATHS.DEFAULT_PATH);
                break;
        }
    };
    const handleSubmit = () => {
        switch (action) {
            //gebruiker verwijderen
            case "delete_user":
                deleteUser(userId);
                break;
            //profiel verwijderen
            case "delete_profile":
                deleteProfile(profilesId);
                break;
            //loskoppelen
            case "detach_user":
                detachUserFromVersion(Number(Id), auth.selectedTab);
                break;
            case "delete_apikey":
                deleteIntegration(apiKey);
                break;
            //default afsluiten
            default:
                endSession(Id, "versions", navigate, auth.selectedTab);
                break;
        }
        handleClose();
    };

    return (
        <Dialog name={"confirmDialog"} open={true} onClose={() => handleClose()}>
            <DialogTitle id="alert-dialog-title" style={{ background: secondaryColor }}>
                {action === "end_session" && (
                    <span style={{ color: "white" }}>{t("confirmComponent.sessionTitle")}</span>
                )}
                {action === "detach_user" && (
                    <span style={{ color: "white" }}>{t("confirmComponent.detachTitle")}</span>
                )}
                {action === "delete_user" && (
                    <span style={{ color: "white" }}>{t("confirmComponent.deleteUTitle")}</span>
                )}
                {action === "delete_profile" && (
                    <span style={{ color: "white" }}>{t("confirmComponent.deletePTitle")}</span>
                )}
                {action === "delete_apikey" && (
                    <span style={{ color: "white" }}>{t("confirmComponent.deleteATitle")}</span>
                )}
            </DialogTitle>

            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {action === "end_session" && <span>{t("confirmComponent.sessionSubTitle")}</span>}
                    {action === "detach_user" && <span>{t("confirmComponent.detachSubTitle")}</span>}
                    {action === "delete_user" && <span>{t("confirmComponent.deleteUSubTitle")}</span>}
                    {action === "delete_profile" && <span>{t("confirmComponent.deletePSubTitle")}</span>}
                    {action === "delete_apikey" && <span>{t("confirmComponent.deleteASubTitle")}</span>}
                </DialogContentText>
                <Grid container>
                    {action === "end_session" && (
                        <Grid item>
                            <IconButton disabled>
                                <Person />
                            </IconButton>
                            {user?.fullName}
                            <IconButton disabled>
                                <Computer />
                            </IconButton>
                            {sessionInfo?.[0]}
                            <IconButton disabled>
                                <Today />
                            </IconButton>
                            {sessionInfo?.[1]}
                            <IconButton disabled>
                                <Schedule />
                            </IconButton>
                            {sessionInfo?.[2]}
                        </Grid>
                    )}
                    {action === "detach_user" && (
                        <Grid item>
                            <IconButton disabled>
                                <Folder />
                            </IconButton>
                            {version.description}
                            <IconButton disabled>
                                <Person />
                            </IconButton>
                            {user?.fullName}
                        </Grid>
                    )}
                    {action === "delete_user" && (
                        <Grid item>
                            <IconButton disabled>
                                <Person />
                            </IconButton>
                            {user?.fullName}
                        </Grid>
                    )}
                    {action === "delete_profile" && (
                        <Grid item>
                            <IconButton disabled>
                                <Assignment />
                            </IconButton>
                            {profile.description}
                        </Grid>
                    )}
                    {action === "delete_apikey" && (
                        <Grid item>
                            <IconButton disabled>
                                <VpnKey />
                            </IconButton>
                            {api.description}
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button name={"noButton"} color="primary" onClick={handleClose}>
                    <Close color="error" />
                    {t("confirmComponent.actionButtonN")}
                </Button>
                <Button name={"yesButton"} color="primary" variant="outlined" onClick={handleSubmit} autoFocus>
                    <Check color="success" />
                    {t("confirmComponent.actionButtonY")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = {
    deleteUser,
    deleteProfile,
    endSession,
    detachUserFromVersion,
    deleteIntegration,
};

export default connect(null, mapDispatchToProps)(ConfirmDialog);

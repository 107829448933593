import * as yup from "yup";

import { getLocale } from "./helper";

export const getProfileValidationScheme = () => {
    yup.setLocale(getLocale("profileComponent.dialog.input"));

    return yup.object({
        description: yup.string().required(),
    });
};

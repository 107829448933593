import * as yup from "yup";

import { getLocale } from "./helper";

export const getIntegrationValidationScheme = () => {
    yup.setLocale(getLocale("integrationComponent.dialog"));

    return yup.object({
        description: yup.string().required(),
        //   .min(1, t("general:dialog.error.short")),
        application: yup.string().required(),
        //   .min(1, t("general:dialog.error.short")),
        licversionsid: yup.number().required(),
    });
};

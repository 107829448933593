import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import "./i18n";
import "./index.css";
import { AuthProvider } from "./services/auth/AuthProvider";
import store from "./store/index";

const root = createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Provider>,
);

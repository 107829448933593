const DEFAULT_PATH = "/users";
const EDIT_PATH = ":userId/edit";
const NEW_PATH = "new_user";
const DELETE_PATH = ":userId/confirm/:action";

export const USER_PATHS = {
    DEFAULT_PATH,
    EDIT_PATH,
    NEW_PATH,
    DELETE_PATH,
};

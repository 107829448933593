/*
Dit is de wijzigenDialoog component. Op deze pagina kan de gegevens van een geselecteerde gebruiker gewijzigd worden.
Deze component wordt aangeroepen vanuit de gebruikers pagina en wordt ingevuld met de gebruiker informatie die geselecteerd werd.
*/
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";

import CustomDialog from "../../../components/CustomDialog";
import { APPLICATIONMANAGER } from "../../../constants/general";
import { DEFAULT_EXPDATE_STRING } from "../../../constants/users";
import { USER_PATHS } from "../../../routes/paths/users";
import { updateUser } from "../../../store/actions";
import { selectAllUsersByUsersId } from "../../../store/selectors";
import { formatDateToYYYYMMDD, formatLanguage } from "../../../utils/helpers";
import { getUserValidationScheme } from "../../../validation/users";
import Content from "./content";

function Update(props) {
    const { userId } = useParams();
    const user = useSelector((state) => selectAllUsersByUsersId(state, Number(userId))) ?? {};
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            usersid: user.usersid,
            isUserChoice: true,
            isTempUser: user?.expdate ?? false,
            totp: user?.totp ?? false,
            totp_reset: false,
            admin: user?.defapps?.some((app) => app.code === APPLICATIONMANAGER) ?? false,
            loginemail: user?.loginemail,
            password: "",
            infixname: user?.infixname,
            lastname: user?.lastname,
            firstname: user?.firstname,
            languagecode: formatLanguage(user?.languagecode, true) || "ENG",
            expdate: user?.expdate,
            showPassword: false,
            isValidPassword: false,
        },
        enableReinitialize: true,
        validationSchema: getUserValidationScheme(),
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    //sluit wijzig gebruiker dialoog
    const handleClose = () => {
        navigate(USER_PATHS.DEFAULT_PATH);
    };

    //wijzigen van de gebruiker in de database
    const handleSubmit = (values) => {
        props.updateUser({
            usersid: Number(values.usersid),
            lastname: values.lastname,
            infixname: values.infixname,
            firstname: values.firstname,
            loginemail: values.loginemail,
            languagecode: formatLanguage(values.language, false),
            admin: values.admin,
            totp: values.totp,
            expdate: values.expdate ? formatDateToYYYYMMDD(values.expdate) : DEFAULT_EXPDATE_STRING,
            evoit: values.loginemail?.includes("evo-it"),
            ...(values.password === "" || values.password === undefined ? {} : { loginpassword: values.password }),
            totp_reset: values.totp_reset,
        });
        handleClose();
    };

    return (
        <CustomDialog
            title={t("userComponent.dialog.dialogTitleU")}
            id="editUserDialog"
            draggable={true}
            animated={true}
            maxWidth="md"
            closeWithIcon={true}
            fullWidth={true}
            open={true}
            handleClose={handleClose}
            handleSubmit={formik.submitForm}
            defaultButtons={[
                {
                    label: t("profileComponent.dialog.dialogActionC"),
                    isPrimary: false,
                    isSubmit: false,
                },
                {
                    label: t("userComponent.dialog.dialogActionS"),
                    isPrimary: true,
                    isSubmit: true,
                },
            ]}
            content={<Content formik={formik} isEdit={true} handleSubmit={handleSubmit} />}
        />
    );
}
const mapDispatchToProps = {
    updateUser,
};
export default connect(null, mapDispatchToProps)(Update);

import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// auth versions
export const selectAllLicVersions = createSelector(scheme, (orm) => {
    return orm.AuthVersions.all()
        .toModelArray()
        .map(({ licversionsid, versioncode, versiondescr }) => ({
            id: licversionsid,
            code: versioncode,
            description: versiondescr,
        }));
});

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import styled from "@emotion/styled";

import { VERSION_PATHS } from "../../routes/paths/versions";
import { useAuth } from "../../services/auth/AuthProvider";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(6)};
    text-align: center;
    background: transparent;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)};
    }
`;

function Page404() {
    const { t } = useTranslation();
    const { auth } = useAuth();
    return (
        <Wrapper>
            <Helmet title="404" />
            <img src="/static/logo-evo-it-404-scala-bold-grijs.png" className="App-logo" alt="404-logo" />

            <Typography component="h1" variant="h2" align="center" gutterBottom color="primary">
                {t("page404Component.title")}
            </Typography>
            <Typography component="h2" variant="body1" align="center" gutterBottom>
                {t("page404Component.subtitle")}
            </Typography>

            {/* geen token aanwezig redirect naar login anders naar de versie */}

            {auth.access_token ? (
                <Button component={Link} to={VERSION_PATHS.DEFAULT_PATH} variant="contained" color="secondary" mt={2}>
                    {t("page404Component.back")}
                </Button>
            ) : (
                <Button component={Link} to="/" variant="contained" color="secondary" mt={2}>
                    {t("page404Component.back")}
                </Button>
            )}
        </Wrapper>
    );
}

export default Page404;

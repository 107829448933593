import { Outlet } from "react-router-dom";

import { CssBaseline } from "@mui/material";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

const globalStyle = css`
    html,
    body,
    #root {
        height: 100%;
    }
`;

const Root = styled.div`
    // max-width: 520px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
    background: ${(props) => props.theme.body.background};
`;

function Auth() {
    return (
        <Root>
            <CssBaseline />
            <Global styles={globalStyle} />
            {/* This will render the children */}
            <Outlet />
        </Root>
    );
}

export default Auth;

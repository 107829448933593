import { createSelector } from "redux-orm";

import scheme from "../../store/models/orm";

// auth licenses
export const selectAllLicenses = createSelector(scheme, (orm) => {
    return orm.AuthLicenses.all()
        .toModelArray()
        .map(({ code, description, licensesid }) => ({
            code: code,
            description: description,
            id: licensesid,
        }));
});

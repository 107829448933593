import { UPSERT_MENU } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal alle menu apps
export const fetchDefapps = () => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/defapps`);

        dispatch({
            type: UPSERT_MENU,
            payload: response.data.value,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

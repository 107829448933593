import { Model, attr } from "redux-orm";

import { CREATE_VERSION, RESET_VERSION, UPDATE_VERSION, UPSERT_VERSION } from "../../constants/redux";

class Versions extends Model {
    static reducer(action, versions) {
        switch (action.type) {
            case CREATE_VERSION:
                versions.create(action.payload);
                break;
            case UPSERT_VERSION:
                action?.payload?.forEach((version) => {
                    versions.upsert({ ...version, ...action.extra });
                });
                break;
            case UPDATE_VERSION:
                versions.withId(action.payload.licappsid).update(action.payload);
                break;
            case RESET_VERSION:
                versions.delete();
                break;
            default:
                break;
        }
    }
}
Versions.modelName = "Versions";

Versions.options = {
    idAttribute: "licappsid", // default idd
};
// attributen
Versions.fields = {
    licappsid: attr(),
    $id: attr(),
    code: attr(),
    description: attr(),
    defappsid: attr(),
    licversionsid: attr(),
    userlicapplinks: attr(),
};

export default Versions;

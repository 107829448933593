import { SET_THEME } from "../../constants/redux";

//zet de default theme 0 default andere nummer indien meer thema aanwezig is
export default function reducer(state = { currentTheme: 0 }, action) {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                currentTheme: action.payload,
            };

        default:
            return state;
    }
}

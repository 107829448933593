import { Outlet } from "react-router-dom";

import { CssBaseline } from "@mui/material";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

const globalStyle = css`
    html,
    body,
    #root {
        height: 100%;
    }
`;

const Root = styled.div`
    display: flex;
    height: 100vh;
`;

function Landing() {
    return (
        <Root>
            <>
                <CssBaseline />
                <Global styles={globalStyle} />
                <Outlet />
            </>
        </Root>
    );
}

export default Landing;

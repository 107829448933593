import { Model, attr } from "redux-orm";

import {
    CREATE_PROFILESETTINGS,
    RESET_PROFILESETTINGS,
    UPDATE_PROFILESETTINGS,
    UPSERT_PROFILESETTINGS,
} from "../../constants/redux";

class ProfileSettings extends Model {
    static reducer(action, profileSettings) {
        switch (action.type) {
            case UPSERT_PROFILESETTINGS:
                profileSettings.upsert(action.payload);
                break;
            case CREATE_PROFILESETTINGS:
                profileSettings.create(action.payload);
                break;
            case UPDATE_PROFILESETTINGS:
                profileSettings.withId(action.payload.profilesid).update(action.payload);
                break;
            case RESET_PROFILESETTINGS:
                profileSettings.delete();
                break;
            default:
                break;
        }
    }
}
ProfileSettings.modelName = "ProfileSettings";

ProfileSettings.options = {
    idAttribute: "profilesid", // default idd
};
// attributen
ProfileSettings.fields = {
    $id: attr(),
    profilesid: attr(),
    defAuthGroups: attr(),
    defAuthLevels: attr(),
};

export default ProfileSettings;

const DEFAULT_PATH = "/profiles";
const EDIT_PATH = ":profilesId/edit";
const NEW_PATH = "new_profile";
const DELETE_PATH = ":profilesId/confirm/:action";

export const PROFILE_PATHS = {
    DEFAULT_PATH,
    EDIT_PATH,
    NEW_PATH,
    DELETE_PATH,
};

import axios from "axios";

import { useAuth } from "../services/auth/AuthProvider";
import { getApbRefreshToken, getURL } from "../utils/common";

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post(
            `${getURL()}refresh`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${auth.refresh_token ?? getApbRefreshToken()}`,
                },
            },
        );
        // opslaan van de nieuwe token
        setAuth((prev) => {
            return { ...prev, ...response.data };
        });

        sessionStorage.setItem("app_oauth", JSON.stringify({ refresh_token: response.data.refresh_token }));

        return response.data;
    };

    return refresh;
};

export default useRefreshToken;

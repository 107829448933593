import { Box, useMediaQuery, useTheme } from "@mui/material";

import styled from "@emotion/styled";
import dayjs from "dayjs";
import { t } from "i18next";
import PropTypes from "prop-types";

import { ERROR } from "../constants/redux";

export const handleErrors = (error) => (dispatch) => {
    const status = error?.response?.status;

    if (error.response) {
        /*
         * Bepaal welke melding getoond moet worden indien response is aanwezig
         */

        // bij 401 error redirect naar inloggen
        if (status === 401) {
            // window.location.href = "/";
            window.location.href = "/renewsession";
        } else {
            dispatch({
                type: ERROR,
                payload: t(
                    `error.${error.response?.data?.error?.code}`,
                    error.response?.data?.error?.message || error.response?.data,
                ),
            });
        }
    } else {
        dispatch({ type: ERROR, payload: error.message });
    }
};

/*
 * Dit is een functie die een boolean terug geeft of je in mobiel of desktop modus zit.
 * Naam geeft aan dat dit een component is, hoewel dit een normale functie is.
 * Dit is gedaan om de useTheme te implementeren, want dit kun je ALLEEN bij een component gebruiken.
 */
export function IsMobile() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("md"));
}

export const TextFieldContainer = styled("div")({
    "& .MuiOutlinedInput-input": {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
        },
    },
});

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export function formatLanguage(lang, isFromDB) {
    if (isFromDB) {
        switch (lang) {
            case "NLD":
            case "nl":
                return "nl";

            case "ENG":
            case "en":
                return "en";

            case "DEU":
            case "de":
                return "de";

            default:
                return "en";
        }
    } else {
        switch (lang) {
            case "nl":
                return "NLD";

            case "en":
                return "ENG";

            case "de":
                return "DEU";

            default:
                return "ENG";
        }
    }
}

// lijst van alle evo-it gebruikers
export const evoitUsers = [
    "jcnrobroeks@evo-it.nl",
    "remerenciana@evo-it.nl",
    "fmeert@evo-it.nl",
    "mtoussaint@evo-it.nl",
    "fvollebregt@evo-it.nl",
    "aintveen@evo-it.nl",
];

export const getEnvironment = (hostname) => {
    switch (hostname) {
        case "login.evo-it.nl":
        case "backoffice.evo-it.nl":
            return "production";
        case "staging-login.evo-it.nl":
        case "staging-backoffice.evo-it.nl":
            return "staging";
        default:
            return "local";
    }
};

export const formatDateToYYYYMMDD = (newDate) => {
    if (!newDate) {
        return "";
    }
    return dayjs(newDate).format("YYYY-MM-DD");
};

import { useState } from "react";
import { Settings as SettingsIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";

import { Autorenew } from "@mui/icons-material";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/nl";
import i18n from "i18next";

import { SmallAvatar } from "../../../../components/styledComponents/SmallAvatar";
import { DEFAULT_EXPDATE_STRING } from "../../../../constants/users";
import { getLanguages } from "../../../../utils/common";
import { formatDateToYYYYMMDD } from "../../../../utils/helpers";

export default function Settings({ formik, isEdit, handleSubmit }) {
    const { t } = useTranslation();
    const [reset, setReset] = useState(false);

    const handleReset2FA = () => {
        setReset(true); // deze update de animatie

        setTimeout(() => {
            setReset(false); // stop de animatie
            handleSubmit({ ...formik.values, totp_reset: true });
        }, 1000);
    };

    return (
        <Card variant="outlined">
            <CardHeader avatar={<SettingsIcon size={20} />} title={t("userComponent.dialog.input.settings")} />
            <Divider />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">{t("userComponent.dialog.input.language")}</FormLabel>
                            <RadioGroup
                                variant="filled"
                                row
                                name="languagecode"
                                value={formik.values.languagecode}
                                onChange={formik.handleChange}
                            >
                                {getLanguages().map((lang) => {
                                    return (
                                        <FormControlLabel
                                            key={lang}
                                            value={lang}
                                            control={<Radio />}
                                            label={
                                                (lang === "nl" && <SmallAvatar src="/flags/nl.png" alt="Dutch" />) ||
                                                (lang === "en" && <SmallAvatar src="/flags/en.png" alt="English" />) ||
                                                (lang === "de" && <SmallAvatar src="/flags/de.png" alt="German" />)
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="admin"
                                    checked={formik.values.admin}
                                    onChange={(event) =>
                                        formik.setValues((val) => ({
                                            ...val,
                                            [event.target.name]: event.target.checked,
                                        }))
                                    }
                                />
                            }
                            label={t("userComponent.dialog.input.admin")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="totp"
                                    value={formik.values.totp}
                                    checked={formik.values.totp}
                                    onChange={(event) =>
                                        formik.setValues((val) => ({
                                            ...val,
                                            [event.target.name]: event.target.checked,
                                        }))
                                    }
                                />
                            }
                            label={t("userComponent.dialog.input.mfa")}
                        />
                        {formik.values.totp && isEdit && (
                            <Tooltip title={t("userComponent.dialog.input.reset_mfa")}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        handleReset2FA();
                                    }}
                                >
                                    <Autorenew className={reset ? "Resend-email" : null} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="flex-start">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="isTempUser"
                                            value={formik.values.isTempUser}
                                            checked={Boolean(formik.values.isTempUser)}
                                            onChange={(event) =>
                                                formik.setValues((val) => ({
                                                    ...val,
                                                    [event.target.name]: event.target.checked,
                                                    expdate: event.target.checked
                                                        ? dayjs(new Date())
                                                        : DEFAULT_EXPDATE_STRING,
                                                }))
                                            }
                                        />
                                    }
                                    label={t("userComponent.dialog.input.temp")}
                                />
                            </Grid>
                            {/*Als de optie tijdelijke contract is geselecteerd toon datum veld */}
                            {formik.values.isTempUser && (
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                                        <DatePicker
                                            disablePast={formik.values.usersid === 0}
                                            sx={{ width: "100%" }}
                                            value={dayjs(formik.values.expdate)}
                                            onChange={(newDate) => {
                                                formik.setValues((val) => ({
                                                    ...val,
                                                    expdate: formatDateToYYYYMMDD(newDate),
                                                }));
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {!formik.values.isUserChoice && (
                        <Grid item xs={12}>
                            <PasswordChecklist
                                rtl={true}
                                rules={["minLength", "capital", "specialChar", "number"]}
                                minLength={8}
                                value={formik.values.password}
                                onChange={(isValid) => {
                                    if (isValid !== formik.values.isValidPassword) {
                                        formik.setValues((values) => {
                                            return { ...values, isValidPassword: isValid };
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}

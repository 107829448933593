import { useTranslation } from "react-i18next";

import { Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";

import PasswordVisibility from "../../../../components/PasswordVisibility";

export default function General({ isEdit, formik }) {
    const { t } = useTranslation();

    return (
        <Card variant="outlined" style={{ height: "100%" }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            margin="dense"
                            id="firstname"
                            name="firstname"
                            label={t("userComponent.dialog.input.firstname")}
                            onChange={formik.handleChange}
                            value={formik.values.firstname ?? ""}
                            onBlur={formik?.handleBlur}
                            error={formik?.touched["firstname"] && Boolean(formik.errors["firstname"])}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            margin="dense"
                            id="infixname"
                            name="infixname"
                            label={t("userComponent.dialog.input.infixname")}
                            onChange={formik.handleChange}
                            value={formik.values.infixname ?? ""}
                            onBlur={formik?.handleBlur}
                            error={formik?.touched["infixname"] && Boolean(formik.errors["infixname"])}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            margin="dense"
                            id="lastname"
                            name="lastname"
                            label={t("userComponent.dialog.input.lastname")}
                            onChange={formik.handleChange}
                            value={formik.values.lastname ?? ""}
                            onBlur={formik?.handleBlur}
                            error={formik?.touched["lastname"] && Boolean(formik.errors["lastname"])}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            margin="dense"
                            id="loginemail"
                            autoComplete="off"
                            name="loginemail"
                            label={t("userComponent.dialog.input.loginemail")}
                            onChange={formik.handleChange}
                            value={formik.values.loginemail ?? ""}
                            onBlur={formik?.handleBlur}
                            error={formik?.touched["loginemail"] && Boolean(formik.errors["loginemail"])}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={8}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    margin="dense"
                                    id="password"
                                    autoComplete="new-password"
                                    inputProps={{
                                        autoComplete: "new-password",
                                    }}
                                    disabled={formik.values.isUserChoice}
                                    name="password"
                                    label={t("userComponent.dialog.input.password")}
                                    onChange={formik.handleChange}
                                    value={formik.values.password ?? ""}
                                    onBlur={formik?.handleBlur}
                                    error={
                                        formik?.touched["isValidPassword"] && Boolean(formik.errors["isValidPassword"])
                                    }
                                    helperText={formik?.touched["isValidPassword"] && formik.errors["isValidPassword"]}
                                    type={formik.values.showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <PasswordVisibility
                                                isPasswordVisible={formik.values.showPassword}
                                                setIsPasswordVisible={(bool) =>
                                                    formik.setValues((values) => ({
                                                        ...values,
                                                        showPassword: bool,
                                                    }))
                                                }
                                                iconButtonProps={{
                                                    disabled: formik.values.isUserChoice,
                                                }}
                                            />
                                        ),
                                    }}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} align="right">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={formik.values.isUserChoice}
                                            onChange={(event) =>
                                                formik.setValues((val) => ({
                                                    ...val,
                                                    [event.target.name]: event.target.checked,
                                                    password: "",
                                                    showPassword: false,
                                                }))
                                            }
                                            name="isUserChoice"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="caption">
                                            {isEdit
                                                ? t("userComponent.dialog.input.changePassword")
                                                : t("userComponent.dialog.input.userChoice")}
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Info } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

function RenewSession() {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleRenew = () => {
        setOpen(false);
        navigate("/");
    };

    return (
        <Dialog id="sessionDialog" maxWidth="sm" open={open}>
            <DialogTitle>{t("sessionComponent.renewTitle")}</DialogTitle>
            <DialogContent>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <Info color="primary" fontSize="large" />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography>{t("sessionComponent.renewMessage")}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleRenew}>
                    {t("loginComponent.loginButton")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default RenewSession;

import { t } from "i18next";

import { APPLICATIONMANAGER, LMSBACKOFFICE } from "../../constants/general";
import { SUCCESS, UPSERT_AMOUNTLICENSEUSERS, UPSERT_VERSION } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal alle versies op
export const fetchVersions = (defappsid) => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/versions`, {
            Top: 100,
            defappid: defappsid,
        });

        dispatch({
            type: UPSERT_VERSION,
            payload: response.data.value,
            extra: { defappsid: defappsid },
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// koppel gebruiker aan een versie
export const attachUserToVersion = (props, defappsid) => async (dispatch) => {
    try {
        const updateResponse = await apiService.post(`appmanagement/userlicapplink`, props);
        // versies updaten
        dispatch(fetchVersions(defappsid));
        // haal aantal lcientie gebruikers
        dispatch(fetchAmountOfLicenseUsers());
        // toon successmelding
        dispatch({
            type: SUCCESS,
            payload: t("versionComponent.messages.add") + ` - ${updateResponse.data.value}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// gebruikersprofiel wijzigen in een versie
export const updateUserProfile = (details, defappsid) => async (dispatch) => {
    try {
        const updateProfile = await apiService.post(`appmanagement/userlicapplink`, details);
        dispatch(fetchVersions(defappsid));
        dispatch(fetchAmountOfLicenseUsers());
        dispatch({
            type: SUCCESS,
            payload: t("versionComponent.messages.update") + ` - ${updateProfile.data.value}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error, updateUserProfile(details)));
    }
};

// loskoppelen van de gebruiker in de versie
export const detachUserFromVersion = (props, defappsid) => async (dispatch) => {
    try {
        await apiService.delete(`appmanagement/userlicapplink`, {
            userlicapplinkid: props,
        });

        dispatch(fetchVersions(defappsid));
        dispatch(fetchAmountOfLicenseUsers());
        dispatch({
            type: SUCCESS,
            payload: t("versionComponent.messages.delete"),
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// haal aantal licentiegebruikers op
export const fetchAmountOfLicenseUsers = () => async (dispatch) => {
    try {
        const defapps = await apiService.get(`appmanagement/defapps`);

        // applicatiebeheer & backoffice uitfilteren
        const filterdDefapps = defapps.data.value?.filter(
            (c) => c.code !== APPLICATIONMANAGER && c.code !== LMSBACKOFFICE,
        );

        filterdDefapps?.forEach(async (app) => {
            const aantal = await apiService.get(`appmanagement/userlimit`, {
                defappsid: app.defappsid,
            });
            dispatch({
                type: UPSERT_AMOUNTLICENSEUSERS,
                payload: { ...aantal.data, $id: app.defappsid },
            });
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

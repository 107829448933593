import { Grid } from "@mui/material";

import General from "./General";
import Settings from "./Settings";

export default function Content({ formik, isEdit, handleSubmit }) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={8}>
                <General formik={formik} isEdit={isEdit} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Settings formik={formik} isEdit={isEdit} handleSubmit={handleSubmit} />
            </Grid>
        </Grid>
    );
}

import { t } from "i18next";
import * as yup from "yup";

import { getLocale } from "./helper";

export const getUserValidationScheme = () => {
    yup.setLocale(getLocale("userComponent.dialog.input"));

    return yup.object({
        firstname: yup.string().required(),
        userChoice: yup.bool(),
        lastname: yup.string().required(),
        loginemail: yup.string().required().email(),
        expdate: yup.string().when("isTempUser", {
            is: true,
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
        }),

        //passwordcheck is uitbesteed aan react-password-checklist. We checken hier dus slechts op of het voldoet of niet
        isValidPassword: yup.string().when("isUserChoice", {
            is: false,
            then: () => yup.boolean().oneOf([true], t("error.password")),
            otherwise: () => yup.boolean().notRequired(),
        }),
    });
};

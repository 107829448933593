import { t } from "i18next";

import { SUCCESS, UPSERT_PROFILESETTINGS } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal van de  geselecteerde profiel de settings op
export const fetchProfileSettings = (profilesid) => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/profileauth`, {
            profilesid: profilesid,
        });

        dispatch({
            type: UPSERT_PROFILESETTINGS,
            payload: { ...response.data, profilesid: profilesid },
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error, fetchProfileSettings(profilesid)));
    }
};

// update de gweijzigde instelling
export const updateProfileSettings = (settings, profilesid) => async (dispatch) => {
    try {
        const response = await apiService.post(
            `appmanagement/profileauth`,

            [settings],
        );
        dispatch(fetchProfileSettings(profilesid));
        dispatch({
            type: SUCCESS,
            payload: t("profileSettingsComponent.update") + `${response.data}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};
